import { Badge } from "@amzn/awsui-components-react";
import { SeverityLevel } from "../severity-badge/SeverityBadge";

export const Alerts = {
  RightAfterBlockedCommit: {
    id: "RightAfterBlockedCommit",
    title: "Bypass right after a blocked commit",
    tagText: "Right after blocked commit",
    description:
      "Triggered when a bypass is flagged within 5 minutes of a blocked commit. This is risky because these can be intentional bypasses that builders choose to skip Code Defender scans to get quickly unblocked.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.Critical,
  },
  MarkedAsIntentional: {
    id: "MarkedAsIntentional",
    title: "Intentional bypasses",
    tagText: "Marked as intentional",
    description:
      "Builder marked these bypasses as intentional. This is risky because builders are skipping Code Defender on purpose.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.High,
  },
  RecurringUnintentional: {
    id: "RecurringUnintentional",
    title: "Recurring unintentional bypasses",
    tagText: "Recurring unintentional",
    description:
      "Builders marked bypasses as unintentional, but this user has new unintentional bypasses within 30 days. This is risky because this builder may not have configured the tool correctly or is encountering an issue with the Code Defender tool itself. If they don't fix their configuration, their commits are not protected by Code Defender.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.High,
  },
  MissingReasonForThirtyPlusDays: {
    id: "MissingReasonForThirtyPlusDays",
    title: "Missing reasons for 30+ days",
    tagText: "Missing reason for 30+ days",
    description:
      "This alert is triggered when a bypass is flagged and the builder has not provided a reason for 30 days. This is risky because some of these bypasses could be intentional.",
    mitigationLink: "https://example.com",
    severityLevel: SeverityLevel.Low,
  },
};

// Gets the properly styled badge component for each tag
export function getTagComponent(tag) {
  let text = "Unknown";
  let color = "severity-neutral";
  switch (tag) {
    case Alerts.RightAfterBlockedCommit.id:
      text = Alerts.RightAfterBlockedCommit.tagText;
      color = "severity-critical";
      break;
    case Alerts.MarkedAsIntentional.id:
      text = Alerts.MarkedAsIntentional.tagText;
      color = "severity-high";
      break;
    case Alerts.RecurringUnintentional.id:
      text = Alerts.RecurringUnintentional.tagText;
      color = "severity-medium";
      break;
    case Alerts.MissingReasonForThirtyPlusDays.id:
      text = Alerts.MissingReasonForThirtyPlusDays.tagText;
      color = "severity-low";
      break;
    default:
      return "-";
  }
  return <Badge color={color}>{text}</Badge>;
}

// Formats the list of tags for the select component of the AllBypassesTable
export function getSelectList() {
  return Object.keys(Alerts).map((key) => ({
    value: Alerts[key].id,
    label: getTagComponent(Alerts[key].id),
  }));
}

// Formats the text for the select component of the AllBypassesTable
export function getSelectText(selected) {
  if (selected.length === getSelectList().length) {
    return "All";
  }
  return `${selected.length} selected`;
}
