import { Container, Header, Link } from "@amzn/awsui-components-react";
import { InternalLink } from "../internal-link";
import { SeverityBadge } from "../severity-badge/SeverityBadge";
import { ValueWithLabel } from "../value-with-label";

export const ComplianceAlertContainer = ({ alert, count }) => {
  return (
    <Container
      fitHeight
      header={
        <Header
          variant="h2"
          info={
            <Link variant="info" href={alert?.mitigationLink}>
              Mitigation
            </Link>
          }
          description={alert?.description || "-"}
          actions={<SeverityBadge severity={alert?.severityLevel} />}
          data-testid={`alert-header-${alert?.id}`}
        >
          {alert?.title || "-"}
        </Header>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "flex-end",
          height: "100%",
        }}
      >
        <div data-testid={`alert-count-${alert?.id}`}>
          <ValueWithLabel label="Alerts">
            <InternalLink
              variant="awsui-value-large"
              href={`/bypasses/all#${alert?.id}`}
            >
              {count}
            </InternalLink>
          </ValueWithLabel>
        </div>
      </div>
    </Container>
  );
};
