import {
  BreadcrumbGroup,
  Grid,
  Header,
  SpaceBetween,
  Spinner,
} from "@amzn/awsui-components-react";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NoVerifyReasonType } from "../../core/constants";
import getBypasses from "../../helpers/getBypasses";
import { Alerts } from "../bypasses-ui/Alerts";
import { FlashbarPublisher } from "../FlashbarPublisher";
import { ComplianceAlertContainer } from "./ComplianceAlertContainer";

export const ComplianceAlerts = () => {
  const [bypasses, setBypasses] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  const fetchBypasses = async () => {
    try {
      setLoading(true);
      const result = await getBypasses();
      setBypasses(result);

      if (
        result.some(
          (bypass) =>
            bypass.noVerifyReasonType === NoVerifyReasonType.Unintentional
        )
      ) {
      }
    } catch (e) {
      FlashbarPublisher.setItems([
        {
          id: "fetch_bypasses_error",
          type: "error",
          dismissible: true,
          dismissLabel: "Dismiss message",
          onDismiss: FlashbarPublisher.clear,
          content: "There was an error fetching your bypasses.",
        },
      ]);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchBypasses();
  }, []);

  return (
    <div data-testid={"bypasses"}>
      <SpaceBetween direction="vertical" size="s">
        <BreadcrumbGroup
          test-id="breadcrumb-group"
          items={[
            { text: "Code Defender", href: "/" },
            { text: "Compliance alerts", href: "/compliance_alerts" },
          ]}
          ariaLabel="Breadcrumbs"
          onClick={(ev) => {
            ev.preventDefault();
            navigate(ev.detail.href);
          }}
        />

        <Header
          variant="h1"
          description="Shows alerts from yourself from
              the last 90 days."
          counter={`(${bypasses.length})`}
        >
          Compliance alerts
        </Header>
        {loading && <Spinner size="large" />}
        {!loading && (
          <Grid
            gridDefinition={[
              { colspan: 6 },
              { colspan: 6 },
              { colspan: 6 },
              { colspan: 6 },
            ]}
          >
            <ComplianceAlertContainer
              alert={Alerts.RightAfterBlockedCommit}
              count={
                bypasses.filter(
                  (bypass) => bypass.type === Alerts.RightAfterBlockedCommit.id
                ).length
              }
            />
            <ComplianceAlertContainer
              alert={Alerts.MarkedAsIntentional}
              count={
                bypasses.filter(
                  (bypass) => bypass.type === Alerts.MarkedAsIntentional.id
                ).length
              }
            />
            <ComplianceAlertContainer
              alert={Alerts.RecurringUnintentional}
              count={
                bypasses.filter(
                  (bypass) => bypass.type === Alerts.RecurringUnintentional.id
                ).length
              }
            />
            <ComplianceAlertContainer
              alert={Alerts.MissingReasonForThirtyPlusDays}
              count={
                bypasses.filter(
                  (bypass) =>
                    bypass.type === Alerts.MissingReasonForThirtyPlusDays.id
                ).length
              }
            />
          </Grid>
        )}
      </SpaceBetween>
    </div>
  );
};
