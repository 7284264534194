import { Badge } from "@amzn/awsui-components-react";

export const SeverityLevel = {
  Critical: "Critical",
  High: "High",
  Medium: "Medium",
  Low: "Low",
  Default: "N/A",
};
export const SeverityBadgeConfig = {
  [SeverityLevel.Critical]: {
    color: "severity-critical",
    text: SeverityLevel.Critical,
  },
  [SeverityLevel.High]: {
    color: "severity-high",
    text: SeverityLevel.High,
  },
  [SeverityLevel.Medium]: {
    color: "severity-medium",
    text: SeverityLevel.Medium,
  },
  [SeverityLevel.Low]: {
    color: "severity-low",
    text: SeverityLevel.Low,
  },
  [SeverityLevel.Default]: {
    color: "severity-neutral",
    text: SeverityLevel.Default,
  },
};

export const SeverityBadge = ({ severity }) => {
  if (Object.values(SeverityLevel).includes(severity)) {
    return (
      <Badge color={SeverityBadgeConfig[severity].color}>
        {SeverityBadgeConfig[severity].text}
      </Badge>
    );
  } else {
    return (
      <Badge color={SeverityBadgeConfig[SeverityLevel.Default].color}>
        {SeverityBadgeConfig[SeverityLevel.Default].text}
      </Badge>
    );
  }
};
