import ServiceAppLayout from "../../components/ServiceAppLayout";
import { ComplianceAlerts } from "../../components/compliance-alerts-ui/ComplianceAlerts";

export const ComplianceAlertsPage = () => {
  return (
    <ServiceAppLayout
      page="ComplianceAlertsPage"
      content={<ComplianceAlerts />}
    />
  );
};
