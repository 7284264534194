import { LocalStorage } from "../core/storage";
import { FEATURE_FLAGS } from "../core/user/user";

export const isBypassesPhase2On = () => {
  let isBypassesPhase2Enabled = false;

  const featureFlags = LocalStorage.get(FEATURE_FLAGS);
  if (featureFlags) {
    isBypassesPhase2Enabled = featureFlags["bypasses_phase_2"];
  }

  return isBypassesPhase2Enabled;
};
