/*
    This module is used as a wrapper around a decoded jwt token.

    The token is retrieved from the cookies and decoded in the constructor.
    The class supports multiple get methods such as name, family_name, full_name,
    email, userId, groups and cognito_username. 

    The classes exposes functions as object properties through the use of get syntax:

    Usage:
    ```
        import { User } from 'Core/user'

        const user = User()
        console.log(user.name) // First name, i.e. "Fred"
        console.log(user.family_name) // Last name, i.e. "Flintstone"
        console.log(user.full_name) // Full concatenated name, i.e. Fred Flintstone
        console.log(user.email) // Email, i.e. "fflintstone@amazon.com"
        console.log(user.userId) // The prefered username (your Amazon logon), i.e. "fflintstone"
        console.log(user.groups) // The groups the user is in:
        // [ "Admin", "us-east-1_hAXUwCdUG_Midway"]
        console.log(user.cognito_username) // The username as seen by cognito, i.e. "Midway_fflintstone

        // You can access the entire decoded token through the class's token property
        console.log(user.token)
        {
            "at_hash": "-IOjzxXiUDZDbKGM6ft7Mw",
            "aud": "ka3611ksg7dh9mr9kp6vij82e",
            "auth_time": 1597361167,
            "cognito:groups": ["Admin", "us-east-1_hAXUwCdUG_Midway"],
            "cognito:username": "Midway_fflintstone",
            "email": "fflintstone@amazon.com",
            "email_verified": false,
            "exp": 1597364767,
            "family_name": "Flintstone",
            "iat": 1597361167,
            "identities": [
                {
                    "dateCreated": "1596655139896",
                    "issuer": null,
                    "primary": "true",
                    "providerName": "Midway",
                    "providerType": "OIDC",
                    "userId": "fflintstone"
                }
            ], 
            "iss": "https://cognito-idp.us-east-1.amazonaws.com/us-east-1_hAXUwCdUG",
            "name": "Fred",
            "nonce": "7BD37-ByOahQOrqFAK0U_rE9OCZcB89wUgBlCPMSwROtAmagN2E15Xr2Utvq4PBuPCBJoqoUvzFHukrX1LIijyvrKIrcnw8urLG1baPM5T1aMfrhTI2q4MdFyGh0xKBkrKTPE0dp71zMUEsPXmzmyP1Dsn82lj3SGI0Fj2Dohjg",
            "preferred_username": "fflintstone",
            "sub": "864a93c8-c576-484b-9c37-dfbf11e9a27f",
            "token_use": "id"
        }
    ```
*/

import { LocalStorage } from "Core/storage";
import { InstallationStatuses } from "../../helpers/get-installation-status";

export const USER_DETAILS = "UserDetails";
export const INSTALL_STATUS = "installStatus";
export const FEATURE_FLAGS = "FeatureFlags";
export const DELEGATIONS = "Delegations";
export const BYPASSES = "bypasses";

export default class User {
  constructor() {
    this.token = LocalStorage.getIdToken();
    this.userDetails = LocalStorage.get(USER_DETAILS);
    this.delegate = LocalStorage.get(DELEGATIONS);
    this.installStatus = LocalStorage.get(INSTALL_STATUS) || "";
  }
  get name() {
    return this.token.name;
  }
  get family_name() {
    return this.token.family_name;
  }
  get full_name() {
    return `${this.name} ${this.family_name}`;
  }
  get email() {
    return this.token.email;
  }
  get userId() {
    return this.token.preferred_username;
  }
  get groups() {
    return this.token.groups;
  }
  get cognito_username() {
    return this.token.username;
  }
  get roles() {
    return this.userDetails?.roles || [];
  }
  get is_manager() {
    return this.userDetails?.is_manager;
  }
  get is_delegate() {
    const condition = (delegation) =>
      delegation["Manager"]["Alias"].toLowerCase() !==
      this.userId.toLowerCase();
    const result = this.delegate?.some(condition);
    return result;
  }
  get installed() {
    return this.installStatus === InstallationStatuses.SuccessfulInstallation;
  }
  get chain() {
    return this.userDetails?.chain || [];
  }
}
